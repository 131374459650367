import React from 'react';
import {graphql, StaticQuery} from 'gatsby';
import {BlogGroupContentProps} from "./type";
import {BlogRecordType} from "../../type";
import {Link} from "../Link";
import {Typography} from "../Typography";
import {HR} from "../HR";
import {BLOG_DETAIL, fillTemplate} from "../../pathTemplates";
import {Box} from "@mui/system";

export function BlogGroupContent({ groupID, slug }: BlogGroupContentProps) {
    return <StaticQuery query={query} render={(data: {
        allMdx: {
            edges: {
                node: BlogRecordType,
            }[];
        };
    }) => {
        const filteredData = data?.allMdx?.edges?.filter(edge => edge.node.frontmatter.groupID === groupID);
        if (filteredData?.length === 0) {
            return null;
        }

        return (
            <div>
                <Typography
                    variant="h2"
                    component="h2"
                    enableVerticalGutter
                >
                    Articles in this series
                </Typography>
                {filteredData?.map(edge => {
                    const itemSlug = edge.node.frontmatter.slug;
                    const title = edge.node.frontmatter.title;

                    if (itemSlug === slug) {
                        return <Box marginBottom={1}>🧐 {title}</Box>
                    }

                    const path = fillTemplate(BLOG_DETAIL, { slug: itemSlug});
                    return <Box key={edge.node.id} marginBottom={1}>👉 <Link to={path}>{title}</Link></Box>;
                })}
                <HR />
            </div>
        )
    }} />;
}

const query = graphql`
    query BlogGroupContentQuery {
        allMdx(
            sort: { fields: [frontmatter___sort], order: ASC }
            filter: { frontmatter: { published: { eq: true }, kind: { eq: "Blog" } } }
        ) {
            edges {
                node {
                    id
                    frontmatter {
                        title
                        groupID
                        slug
                    }
                }
            }
        }
    }
`;
