import React from 'react';
import {MDXHeader} from "../../../util/mdx";
import {Typography} from "../../Typography";
import {Container} from "../../Container";
import {Link} from "../../Link";
import {Box} from "@mui/system";
import {HR} from "../../HR";
import {makeSlug} from "../../../util/etc";

type TableOfContentsProps = Partial<{
    headers: MDXHeader[];
}>;

export function TableOfContents({headers}: TableOfContentsProps) {
    if (!headers) {
        return null;
    }

    return (
        <div>
            <Container>
                <Typography variant="h2" component="h2">
                    Table of contents
                </Typography>
                {
                    headers?.map(({depth, text}) => {
                        const key = `${depth}-${text}`;
                        const slug = makeSlug(text);

                        return (
                            <Box key={key} marginLeft={3 * (depth - 2)} marginBottom={1}>
                                <Link to={`#${slug}`}>{text}</Link>
                            </Box>
                        );
                    })
                }
            </Container>
            <HR />
        </div>
    );
}
