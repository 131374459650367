import React, { FC, useMemo } from 'react';
import { graphql } from 'gatsby';
import { MDXRenderer } from 'gatsby-plugin-mdx';

import { BlogDetailPropsType } from './type';
import { PageLayout } from '../PageLayout';
import { Container } from '../Container';
import { BlogPost } from './style';
import { BlogPostHeader } from './BlogPostHeader';
import { BlogPostPageContainer } from './BlogPostPageContainer';
import { Link } from '../Link';
import { SRLWrapper } from 'simple-react-lightbox';
import { lightBoxOptions } from '../../lib/util';
import {BlogGroupContent} from "../BlogGroupContent";
import {extractMDXHeaders} from "../../util/mdx";
import {TableOfContents} from "./TableOfContents";

/**
 * This component is for wrapping up pages that lay in the content/blog/ folder.
 * See gatsby-node.js for details.
 */
export const BlogDetail: FC<BlogDetailPropsType> = ({
    data: { mdx },
    path,
}) => {
    const location = useMemo(() => ({ pathname: path }), [path]);
    const groupID = mdx.frontmatter.groupID ?? "";
    const slug = mdx.frontmatter.slug ?? "";

    const { rawBody } = mdx;
    const headers = useMemo(() => extractMDXHeaders(rawBody), [rawBody]);

    return (
        <PageLayout pageContext={mdx} location={location}>
            <BlogPost>
                <BlogPostHeader data={mdx} />
                {
                    groupID !== ""
                    &&
                    <Container>
                        <BlogGroupContent groupID={groupID} slug={slug} />
                    </Container>
                }
                <BlogPostPageContainer>
                    <TableOfContents headers={headers} />
                    <SRLWrapper options={lightBoxOptions}>
                        <MDXRenderer pageContext={mdx}>{mdx.body}</MDXRenderer>
                    </SRLWrapper>
                    <br />
                    <Container>
                        <Link
                            to={
                                mdx.frontmatter.published
                                    ? '/blog'
                                    : '/blog-drafts'
                            }
                        >
                            &larr; Back to list
                        </Link>
                    </Container>
                </BlogPostPageContainer>
            </BlogPost>
        </PageLayout>
    );
};

export const BlogDetailQuery = graphql`
    query BlogDetailQuery($id: String) {
        mdx(id: { eq: $id }) {
            id
            body
            rawBody
            frontmatter {
                title
                keywords
                description
                displayPageTitle
                published
                date
                updatedAt
                groupID
                slug
                images {
                    isCover
                    galleryId
                    author
                    source
                    sourceText
                    title
                    image {
                        childImageSharp {
                            gatsbyImageData(
                                layout: CONSTRAINED
                                width: 3000
                                quality: 80
                                placeholder: BLURRED
                            )
                        }
                    }
                }
            }
        }
    }
`;

export default BlogDetail;
