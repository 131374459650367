import { fromMarkdown } from 'mdast-util-from-markdown';
import { mdxFromMarkdown } from 'mdast-util-mdx';
import { visit } from 'unist-util-visit';
import type { Heading } from 'mdast';

export type MDXHeader = {
    depth: number;
    text: string;
};

const isHeading = (node: any): node is Heading => {
    return node.type === "heading";
};

export function extractMDXHeaders(mdxContent: string): MDXHeader[] {
    const ast = fromMarkdown(mdxContent, null,{
        mdastExtensions: [mdxFromMarkdown()],
    });

    const headers: MDXHeader[] = [];

    let frontmatterSkipped = false;

    visit(ast, 'heading', (node) => {
        if (isHeading(node)) {
            if (!frontmatterSkipped) {
                frontmatterSkipped = true;
                return;
            }

            const depth = node.depth;
            const text = node.children
                .filter((child) => child.type === 'text')
                .map((child) => child.value)
                .join('');
            headers.push({ depth, text });
        }
    });

    return headers;
}
