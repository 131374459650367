import { align } from '@gannochenko/etc';
import { muiSpacing } from '@gannochenko/ui.styled-components';
import { Link } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { css } from 'styled-components';
import { Container } from '../../Container';

export const BlogPostHeaderContainer = styled.div`
    margin-bottom: 2rem;
`;

export const BlogPostHeaderContainerInner = styled.div`
    position: relative;
`;

export const CoverContainer = styled.div`
    ${align('center', 'center')};
`;

export const Cover = styled(GatsbyImage)`
    height: 80vh;
    ${({ theme }) => css`
        ${theme.breakpoints.only('xs')} {
            height: ${muiSpacing(100)};
        }
    `};
    user-select: none;
`;

export const IntroBlock = styled.div`
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.7);
`;

export const Title = styled.h1`
    padding: 2rem 0;
    margin: 0;
    color: ${(props) => props.theme.palette.text.inverted};
    font-family: ${(props) => props.theme.typography.emphasis.fontFamily};
    font-size: 2.4rem;
    font-weight: 700;
    ${({ theme }) => css`
        ${theme.breakpoints.down('sm')} {
            font-size: 1.5rem;
        }
    `}
`;

export const Date = styled.div<{ update?: boolean }>`
    text-align: right;
    font-size: ${(props) => props.theme.typography.small.fontSize};
    font-weight: bold;
    color: ${({ update, theme }) =>
        update
            ? theme.palette.background.default
            : theme.palette.background.dark};
    background-color: ${({ update, theme }) =>
        update ? theme.palette.primary.main : theme.palette.background.default};
    padding: 0.3rem 1rem;
`;

export const InfoStripeContainer = styled(Container)`
    display: flex;
    justify-content: space-between;
    font-size: ${({ theme }) => theme.typography.micro.fontSize};
`;

export const DateStripe = styled.div`
    background-color: ${(props) => props.theme.palette.background.dark};
`;

export const BlackLink = styled(Link)`
    color: white;
    padding: 0.3rem 1rem 0.3rem 0;
    text-decoration: none;
    font-weight: normal;
    font-size: ${(props) => props.theme.typography.small.fontSize};
`;

export const Dates = styled.div`
    display: flex;
`;
