import React, { FC, useRef } from 'react';

import { BlogPostPageContainerContainer } from './style';
import { BlogPostPageContainerPropsType } from './type';
import { Cup } from '../../Cup';
import { AboutAuthor } from '../../AboutAuthor';
import { PageOffset } from '../../';

export const BlogPostPageContainer: FC<BlogPostPageContainerPropsType> = ({
    children,
}) => {
    const containerRef = useRef(null);

    return (
        <BlogPostPageContainerContainer ref={containerRef}>
            <Cup verticalConstraint={containerRef} />
            {children}
            <PageOffset />
            <AboutAuthor />
        </BlogPostPageContainerContainer>
    );
};
